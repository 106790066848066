/** @jsx jsx */
import { jsx, Themed } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Project = ({
  symbol,
  title,
  description,
  link,
  bgColor,
  symbolColor = "text",
}) => (
  <div>
    <a href={link} sx={{ textDecoration: "none" }}>
      <div
        sx={{
          display: `flex`,
          justifyContent: `center`,
          alignItems: `center`,
          height: "16rem",
          p: 4,
          mb: 3,
          backgroundColor: bgColor,
          fontSize: "32px",
          fontWeight: 500,
          color: symbolColor,
          textTransform: "uppercase",
          transition: "transform 0.2s",
          ":hover": {
            transform: "scale(1.1)",
          },
        }}
      >
        {symbol}
      </div>
    </a>
    <h1 sx={{ fontSize: 1, fontWeight: 500, mb: 1 }}>{title}</h1>
    <p sx={{ fontSize: 1, mt: "-.125rem", color: "#888" }}>{description}</p>
  </div>
)

const ProjectsPage = () => (
  <Layout>
    <SEO title="Projects" />
    <Themed.h1 sx={{ mb: 4 }}>Projects</Themed.h1>
    <div
      sx={{
        display: `grid`,
        gridTemplateColumns: ["auto", "1fr 1fr"],
        gridGap: `1.5rem`,
      }}
    >
      <Project
        symbol="未来"
        title="Mirai Labs"
        description="Innovation lab and product design studio."
        link="https://mirailabs.io"
        bgColor="#F2F2F2"
      />
      <Project
        symbol="丸"
        title="Maru"
        description="Turn your phone into a PC."
        link="https://maruos.com"
        bgColor="#6FCF97"
        symbolColor="white"
      />
      <Project
        symbol="音楽"
        title="D'Souza & Le Project"
        description="Interstellar progressive rock."
        link="https://dsouzaleproject.bandcamp.com"
        bgColor="#333333"
        symbolColor="white"
      />
    </div>
  </Layout>
)

export default ProjectsPage
